$main_color: #3a31b2;

.as-separator {
  width: 100%;
  height: 1px;
}

.white {
  background-color: #ffffff;
}

.purple {
  background-color: $main_color;
}
