$main_color: #3a31b2;

.avatar {
  z-index: -1;
}
h1,
h2,
h3,
h4,
h5,
p,
span {
  text-align: center;
}

h1 {
  font-weight: bold;
}

.purple-box {
  background-color: $main_color;
  color: #ffffff;
  padding: 40px;
}

.services-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 70px 0 50px 0;

  p {
    max-width: 1000px;
  }

  button {
    width: fit-content;
  }
}
.whatsapp-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #25d365;
  width: 50px;
  height: 50px;
  border-radius: 15px;
  margin-right: 20px;
  margin-bottom: 20px;
  position: fixed;
  right: 0;
  bottom: 0;
  transition: 0.3s ease-in;

  &:hover {
    background-color: #25d365d0;
  }

  .notification {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    background-color: rgb(255, 0, 0);
    border-radius: 50px;
    position: absolute;
    top: -5px;
    right: -5px;

    span {
      color: #ffffff;
      font-weight: bold;
      padding: 5px;
    }
  }

  img {
    width: 30px;
    height: 30px;
  }
}
