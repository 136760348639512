$main_color: #3a31b2;
$main_color_opacity: #3a31b2bb;

.project-img {
  width: 100%;
  border-radius: 10px;
  max-height: 143px;
}

.img-box {
  border-radius: 10px;
  position: relative;
  display: inline-block;
  overflow: hidden;
  cursor: pointer;
  width: 100%;
}
.img-box img {
  display: block;
  transition: 0.2s ease-in-out;
}
.img-box:hover img {
  transform: scale(1.1);
  opacity: 0.6;
}

.img-name {
  position: absolute;
  z-index: 2;
  color: #000000;
  background-color: #ffffff;
  border-radius: 10px;
  width: fit-content;
  height: fit-content;
  padding: 5px 10px;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  transition: ease-in 0.5s;
  font-size: 12pt;
  font-weight: bold;
}

.img-box:hover .img-name {
  visibility: visible;
  opacity: 1;
}

.iframe-project {
  width: 100%;
  height: 70vh;
  border-radius: 10px;
}
