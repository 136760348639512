$main_color: #3a31b2;

.as_modalContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color: #cdcdcd, $alpha: 0.8);
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;

  .as_modal {
    width: 30vw;
    padding: 2rem;
    border-radius: 20px;
    z-index: 3;
    background-color: #ffffff;
    box-shadow: 0 0 1rem 0 #1d1d1d;
    overflow-y: scroll;

    &_header {
      position: relative;
      border-bottom: 1px solid #dddddd;
      &_title {
        text-align: center;
        color: $main_color;
      }

      .link-page {
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        width: fit-content;
      }

      .close {
        position: absolute;
        top: 0;
        right: 0;
        background: transparent;
        cursor: pointer;

        img {
          width: 1rem;
          height: auto;
          transition: all 0.3s;
        }
        &:hover {
          img {
            transform: scale(1.1);
          }
        }
      }
    }

    &_body {
      border-bottom: 1px solid #dddddd;
      padding: 2rem 0;
    }

    &_footer {
      padding: 2rem 0;
      padding-bottom: 50px;

      button {
        float: right;
        padding: 0.5rem;

        border-radius: 8px;
      }
      .modal-close {
        background-color: transparent;
        font-weight: 600;

        &:hover {
          color: rgba(54, 67, 72, 0.8);
        }
      }
      .submit {
        margin-right: 1rem;
        background-color: #364348;
        color: $main_color;

        &:hover {
          background-color: rgba(54, 67, 72, 0.8);
        }
      }
    }
  }
}

@keyframes fade-out {
  from {
    opacity: 100%;
  }
  to {
    opacity: 0%;
  }
}

@keyframes fade-in {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}

@media (max-width: 1070px) {
  .as_modalContainer .as_modal {
    width: 97vw !important;
  }
}
