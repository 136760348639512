.circle {
  border: 1px solid #ffffff;
  border-radius: 50px;
}

.rrss-circle {
  border: 1px solid #ffffff;
  border-radius: 10px;
  padding: 8px;
}

.rrss-icons {
  img,
  a {
    transition: ease 0.3s;
  }
  a:hover img {
    scale: 1.3;
  }

  a:hover {
    padding: 12px;
  }
}

.rrss-container {
  margin-top: 20px;
}

@media (max-width: 400px) {
  .rrss-container {
    margin-top: 40px;
    justify-content: center;
    display: flex;
  }
}
