.large {
  width: 400px !important;
}
.spotify-card {
  display: flex;
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #878787;
  padding: 10px 10px;
  border-radius: 10px;
  width: 300px;

  .song-img-container {
    width: 30%;
  }
  .song-img {
    border-radius: 10px;
    width: 100%;
  }

  .song-title {
    font-weight: bold;
    font-size: 13pt;
  }

  .spotify-logo {
    width: 20px;
    height: 20px;
  }

  .link-to-song {
    height: fit-content;
  }

  .song-subtitle {
    margin-top: -5px;
    font-size: 10pt;
  }
}

@media (max-width: 405px) {
  .large {
    width: 100% !important;
  }
  .spotify-card {
    .song-title {
      font-size: 4vw;
    }
    .song-subtitle {
      font-size: 4vw;
    }
  }
}
