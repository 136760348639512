.white-container {
  background-color: #ffffff;
  width: 100%;
  height: fit-content;
  padding: 30px;
  border-radius: 20px;
  color: #000000;
}

.beginner {
  font-weight: bold;
  background-color: #ff0000;
  color: #ffffff;
  border-radius: 10px;
  padding: 10px 20px;
  width: 100%;
}

.intermediate {
  font-weight: bold;
  background-color: #ff7700;
  color: #ffffff;
  border-radius: 10px;
  padding: 10px 20px;
  width: 100%;
}

.expert {
  font-weight: bold;
  background-color: #3db815;
  color: #ffffff;
  border-radius: 10px;
  padding: 10px 20px;
  width: 100%;
}

@media (max-width: 340px) {
  .purple-box {
    padding: 10px !important;
  }
  .white-container {
    padding: 20px;
  }
  .expert,
  .beginner,
  .intermediate {
    font-size: 13pt;
  }
}

@media (max-width: 240px) {
  .purple-box {
    padding: 1px !important;
  }
  .white-container {
    padding: 10px;
  }
  .expert,
  .beginner,
  .intermediate {
    font-size: 10pt;
  }
}
